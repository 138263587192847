import React from "react";


const seoData = [
  {
    language: "en",
    title:
      "Generate Strong Passwords with generatepasswordto.me | Secure Your Accounts",
    description:
      "Enhance your online security with generatepasswordto.me! Use our advanced password generator to create robust and secure passwords for your accounts. Safeguard your digital presence and stay one step ahead of cyber threats.",
  },
  {
    language: "ua",
    title:
      "Генерація Надійних Паролів на generatepasswordto.me | Захистіть свої облікові записи",
    description:
      "Підвищте безпеку онлайн з generatepasswordto.me! Використовуйте наш продуктивний генератор паролів для створення міцних та надійних паролів для ваших облікових записів. Захистіть свою цифрову присутність та будьте на крок попереду від кіберзагроз.",
  },
];

export default seoData;
