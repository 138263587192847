export function rankColor(CrackScore) {
    switch (CrackScore) {
      case 4:
        return "green";
      case 3:
        return "orange";
      case 2:
        return "red";
      case 1:
        return "red";
      default:
        return "red";
    }
  }